import React from "react";
// @ts-ignore
import * as styles from "./placeholder.module.css";

export default function Placeholder({ style, className = '' }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={`${styles.placeholderContainer} ${className}`}
      style={style}
    />
  );
}