import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getImageLink } from '../../helpers/images';

import Slider from '../Slider';

import * as styles from './Gallery.module.css';

/**
 *
 * @param {{
 *   images: import("../../types/global").ProductType["images"].
 *   alt: string
 * }} props
 * @returns
 */
const Gallery = ({ images, alt }) => {
  const customSliderSettings = {
    slidesToShow: 1,
    infinite: false,
  };

  const constructProductImage = ({ src, id, height, width }, withPlaceholder = false, loading = 'lazy') => {
    let image = {
      height,
      width,
      backgroundColor: '#B59F66aa',
      layout: 'fullWidth',
      images: {
        fallback: {
          src,
        },
        sources: [
          {
            media: '',
            type: 'image/webp',
            srcSet: getImageLink(src),
          },
        ],
        shouldLoad: false
      },
    };

    if (withPlaceholder) {
      const fallback = getImageLink(src, { formats: 'webp', query: '&q=1&blur=100' });
      image.placeholder = {
        fallback,
        loading,
        sources: [
          {
            media: '',
            type: 'image/webp',
            srcSet: fallback,
          },
        ],
      };
    }

    return image;
  };

  const renderImages = () => {
    return images?.map((image, index) => {
      const loading = index === 0 ? 'eager' : 'lazy';
      const productImage = constructProductImage(image, true, loading);
      return (
        <div
          key={`gallery-image-${image.id}`}
          className={styles.imageContainer}
        >
          <GatsbyImage
            image={productImage}
            loading={loading}
            alt={`${alt} ${index + 1}`}
            className={styles.image_bg}
          />
        </div>
      );
    });
  };
  const preloadImage = {
    rel: "preload",
    fetchpriority: "high",
    as: "image",
    href: getImageLink(images?.[0]?.src),
    type: "image/webp"
  };

  return (
    <div className={styles.root}>
      <Helmet
        link={[preloadImage]}
      />
      <div className={styles.cardGrid}>
        {images?.map((image, index) => {
          const loading = index < 1 ? 'eager' : 'lazy';
          const productImage = constructProductImage(image, true, loading);
          return (
            <div key={index} className={`${styles.imageContainer}`}>
              <GatsbyImage
                image={productImage}
                loading={loading}
                alt={`${alt} ${index + 1}`}
                className={styles.image_bg}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.mobileSlider}>
        <Slider settings={customSliderSettings}>
          {images && renderImages()}
        </Slider>
      </div>
    </div>
  );
};

export default Gallery;
