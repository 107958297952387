import React, { useState, useContext, useReducer, useRef, useEffect } from 'react';
import { graphql, navigate, Script } from 'gatsby';
import loadable from '@loadable/component';

import Placeholder from '../../components/placeholder';
import Accordion from '../../components/Accordion';
import AdjustItem from '../../components/AdjustItem';
import Button from '../../components/Button';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Gallery from '../../components/Gallery';
import SizeList from '../../components/SizeList';
import ProductDetailsList from '../../components/product-details-list';
import Layout from '../../components/Layout/Layout';
import PriceTag from '../../components/PriceTag';
import FormFieldError from '../../components/form-field-error';
import Seo from '../../components/seo';
import Alert from '../../components/alert';
import httpRequest from "../../helpers/http";
import CartStorage from "../../helpers/storage";
import { addToCart, customEvent, sendMetaEventsToServerByStore } from "../../helpers/analytics/facebook-pixel";
import Icon from '../../components/Icons/Icon';
import { globalReducer } from "../../helpers/reducers";
import { objectifyFormData } from "../../helpers/form";
import { constructCartDataByProduct } from "../../helpers/cart";
import { validateOrderLineItem } from "../../helpers/validator/cart";
import { scrollIntoView } from "../../helpers/ui";
import AddItemNotificationContext from '../../context/AddItemNotificationProvider';
import { extractStoreKeyFromShippingUrl } from "../../helpers/data/tools/";
import useProductAvailability from "../../helpers/hooks/use-product-availability"
import * as styles from './index.module.css';
import "../../assets/css/ql.css";

const initState = {
  loading: false,
  data: null,
  errors: null,
  message: null,
};
const loadableInitState = {
  ...initState,
  data: {
    ProductsRows: null,
  }
};
/**
 *
 * @param {{
 *   data: {
 *     product: import("../../types/global").ProductType
 *   }
 * }} param0
 * @returns
 */
const ProductPage = ({
  data: { product, recommendedProducts: { nodes: products }, categories: { nodes: productCategories }, storeDetails },
}) => {
  const [loadableState, dispatchLoadable] = useReducer(globalReducer, loadableInitState);
  const alertRef = useRef(null);
  const fbEventID = useRef(`event.id.${Math.random() * 1000000}-${Date.now()}`).current;
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [isNotAvailableInStock] = useProductAvailability({
    dispatch,
    product_id: product.wordpress_id,
    product_stock_url: storeDetails.product_stock_url,
    facebookPixelId: storeDetails.store_primary_settings.store_analytics?.facebook_pixel,
  });
  const ctxAddItemNotification = useContext(AddItemNotificationContext);
  const showNotification = ctxAddItemNotification.showNotification;
  const [qty, setQty] = useState(1);
  const facebookPixelId = storeDetails.store_primary_settings.store_analytics.facebook_pixel;
  const productDates = {
    first_publication_date: product.date_created.date,
    last_publication_date: product.date_modified.date,
  };
  const alertMessage = state.errors?.global || state.message;
  const alertVariant = !!state.errors?.global ? 'danger' : 'success';
  const storeKey = extractStoreKeyFromShippingUrl(storeDetails.store_shipping);
  const onSubmit = (mode = "add-to-cart") => async (event) => {
    event.preventDefault();
    if (isNotAvailableInStock) return;

    dispatch({
      type: "LOADING",
      payload: true
    });
    !!facebookPixelId && customEvent("StartAddToCart", {
      content_name: product.name,
      price: product?.price,
      regular_price: product?.regular_price,
      contents: [{ id: product.wordpress_id, quantity: 1, product_build_id: product.id }],
      timestamp: Date.now()
    });
    // form data
    const form = mode === "add-to-cart"
      ? event.currentTarget
      : document.querySelector(".js-buy-now-form");
    const formData = new FormData(form);
    const orderData = objectifyFormData(formData);
    const lineItem = constructCartDataByProduct(orderData);
    const errors = validateOrderLineItem(lineItem, product?.attributes);
    const cart = new CartStorage();
    const cartItems = await cart.getAllItems();
    /**
     * Verify if the given product is already in the cart
     * then add that quantity to the new one and check its availability
     */
    const existingProductInCart = cartItems?.find((item) => `${item.product_id}` === `${orderData.product_id}`);
    let productQuantityToCheck = Number(orderData.quantity);

    if (!!existingProductInCart) {
      productQuantityToCheck += Number(existingProductInCart.quantity);
    }

    if (errors !== false) {
      await dispatch({
        type: "ERROR",
        payload: errors
      });
      // scroll to the element with error message
      if (!errors?.global) {
        const errorElement = document.querySelector('.js-invalid-feedback');
        scrollIntoView(errorElement, -150);
      }
      !!facebookPixelId && customEvent("ValidationErrorInAddToCart", {
        contents: errors
      });
      return false;
    }
    // verify product stock availability
    const response = await httpRequest({
      url: `${storeDetails.product_stock_url}/${orderData.product_id}/availability?quantity=${productQuantityToCheck}`,
      requestConfig: {
        method: "GET",
      }
    });

    if (response?.code !== "success") {
      await dispatch({
        type: "ERROR",
        payload: {
          global: "نفد هذا المنتج من المخزن"
        } || response.errors
      });
      // scroll to field error
      if (!response?.errors?.global) {
        const errorElement = document.querySelector('.js-invalid-feedback');
        scrollIntoView(errorElement, -150);
      }
      !!facebookPixelId && customEvent("ServerErrorInAddToCart", {
        contents: response.errors
      });
      !!facebookPixelId && customEvent("ProductStockCheck", {
        status: "outofstock"
      });
      return;
    } else {
      const productDetails = {
        id: String(product.wordpress_id),
        slug: product.slug,
        name: product.name,
        regular_price: product.price || product.regular_price,
        thumbnail: product.thumbnail,
      }
      await Promise.all([
        cart.addItem(lineItem),
        cart.pushItemDetails(productDetails)
      ]);
      mode === "add-to-cart" && dispatch({
        type: "SUCCESS",
        payload: "Ajouter au panier avec succes"
      });
      showNotification({
        ...product,
        quantity: orderData.quantity,
        options: lineItem.meta_data
      });
      !!facebookPixelId && customEvent("ProductStockCheck", {
        status: "instock"
      });
    }
    // send pixel event
    !!facebookPixelId && addToCart(product, lineItem?.quantity || 1, {
      pixelId: facebookPixelId,
      storeKey,
      sourceUrl: window.location.href
    });
    // navigate to checkout if a customer presson "buy now" button
    if (mode === "buy-now") {
      !!facebookPixelId && customEvent("StartNavigatingToCheckout", {
        timestamp: Date.now()
      });
      await navigate("/cart/checkout");
    }
  }
  const onCloseAlert = () => {
    alertRef.current.classList.add("fade-down");
    const timerId = setTimeout(() => {
      dispatch({
        type: "SUCCESS_WITH_ERRORS",
        payload: {
          errors: null,
          message: null
        }
      });
      clearTimeout(timerId);
    }, 600);
  };
  const onEdit = (quantity) => {
    !!facebookPixelId && customEvent("EditProductQuantity", {
      contents: [{ id: product.wordpress_id, quantity }],
      timestamp: Date.now()
    });
    return true;
  }
  const constructCrumbs = (categories) => {
    let crumbs = [
      { link: '/', label: 'Accueil' },
      { label: 'Boutique', link: '/shop/' },
    ];
    categories.forEach(category => {
      crumbs.push({
        label: category.name,
        link: `/category/${category.slug}/`,
      });
    });
    crumbs.push({
      label: `${product.name}`,
      link: `/product/${product.slug}/`
    });

    return crumbs;
  }

  useEffect(() => {
    let recommendationTitle = null;
    let observer;

    if (typeof window !== 'undefined') {
      // load components
      recommendationTitle = document.querySelector('.js-recommanded-products');
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
      const observerCallback = (entries) => {
        entries.forEach((entry) => {
          const { ProductsRows } = loadableState?.data;

          if (entry.isIntersecting === true && ProductsRows === null) {
            const placeholderStyle = {
              height: 250
            };
            const _productRows = loadable(() => import('../../components/ProductCardGrid'), {
              fallback: <Placeholder style={placeholderStyle} />
            });
            dispatchLoadable({
              type: "SET_FIELDS",
              payload: {
                ProductsRows: _productRows,
              }
            });
            observer?.unobserve(recommendationTitle);
            !!facebookPixelId && customEvent("LoadRecommendedProducts");
          }
        })
      }
      observer = new IntersectionObserver(observerCallback, options);
      observer.observe(recommendationTitle);
      !!facebookPixelId && sendMetaEventsToServerByStore(
        `${storeKey}`,
        {
          pixelId: `${facebookPixelId}`,
          eventData: {
            event_id: fbEventID,
            event_name: "ViewContent",
            action_source: "website",
            event_source_url: window.location.href
          },
          customData: {
            content_name: `${product.name}`,
            content_ids: [`${product.wordpress_id}`],
            content_type: "product",
            contents: [{ id: `${product.wordpress_id}`, quantity: 1 }],
            value: Number(product.price),
            currency: "DZD",
          }
        }
      );
    }

    return () => {
      recommendationTitle !== null && observer?.unobserve(recommendationTitle);
    }
  }, []);


  return (
    <Layout>
      <Seo
        banner={String(product.images?.[0].src)}
        pathname={`/product/${product.slug}/`}
        description={product?.short_description || product?.name || ""}
        title={product.name}
        node={productDates}
        product={product}
        article={false}
      />
      <Alert
        title={'Ajouter au panier'}
        message={alertMessage}
        variant={alertVariant}
        onClose={onCloseAlert}
        reference={alertRef}
        className='alert'
      />
      <div className={styles.root}>
        <Container size={'large'} spacing={'min'}>
          <Breadcrumbs crumbs={constructCrumbs(productCategories)} />
          <div className={styles.content}>
            <div className={styles.gallery}>
              <Gallery images={product.images} alt={product.name} />
            </div>
            <form
              className={`${styles.details} js-buy-now-form`}
              onSubmit={onSubmit()}
            >
              <input name="product_id" value={product.wordpress_id} type="hidden" />
              <h1>{product.name}</h1>
              {
                !!storeDetails?.store_name && (
                  <div className={styles.vendor}>
                    Par{` `}
                    <strong>{storeDetails?.store_name}</strong>
                  </div>
                )
              }

              <PriceTag
                price={product.price}
                regular_price={product.regular_price}
                className={styles.priceContainer}
              />

              {product?.attributes?.map((attribute) => {
                if (attribute.visible === false) return null;
                return (
                  <div className={styles.sizeContainer} key={`product-attribute-${attribute.name}-${attribute.id}`}>
                    <SizeList
                      sizeList={attribute.options}
                      label={attribute.name}
                    />
                  </div>
                );
              })}
              <FormFieldError
                message={state.errors?.options}
                className='js-invalid-feedback'
              />

              <div className={styles.mainActionsContainer}>
                <div>
                  <div className={styles.quantityContainer}>
                    <label htmlFor='quantity'>Quantité</label>
                    <AdjustItem
                      qty={qty}
                      setQty={setQty}
                      onEdit={onEdit}
                    />
                  </div>
                  <FormFieldError
                    message={state.errors?.quantity}
                    className='js-invalid-feedback'
                  />
                </div>
                <div className={styles.actionContainer}>
                  <div className={styles.addToButtonContainer}>
                    <Button
                      onClick={onSubmit("buy-now")}// () => showNotification()
                      fullWidth
                      level={'primary'}
                      type={'button'}
                      disabled={isNotAvailableInStock || state.loading}
                      aria-label='Acheter maintenant'
                    >
                      {isNotAvailableInStock ? 'Rupture' : 'Acheter'}
                      {` `}
                      <span className="sm-hide">
                        {isNotAvailableInStock ? 'de stock' : 'maintenant'}
                      </span>
                    </Button>
                  </div>
                  <Button
                    className={styles.wishlistActionContainer}
                    type="submit"
                    disabled={isNotAvailableInStock || state.loading}
                    aria-label='Ajouter au panier'
                  >
                    <Icon symbol={'bagPlus'}></Icon>
                  </Button>
                </div>
              </div>

              <div className={styles.description}>
                <p>{product.short_description}</p>
                <span>Product code: {product.sku}</span>
              </div>

              <div className={styles.informationContainer}>
                <Accordion
                  type={'plus'}
                  customStyle={styles}
                  title={'Description'}
                >
                  <p
                    className={`ql-container ${styles.information}`}
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </Accordion>
                <Accordion
                  type={'plus'}
                  customStyle={styles}
                  title={'Dimensions et Poids'}
                >
                  <ProductDetailsList
                    product={product}
                    className={styles.information}
                  />
                </Accordion>
              </div>
            </form>
          </div>
          <div className={styles.suggestionContainer}>
            <h2 className='js-recommanded-products'>Tu pourrais aussi aimer</h2>
            {
              loadableState?.data?.ProductsRows !== null && (
                <loadableState.data.ProductsRows
                  spacing
                  showSlider={products?.length > 1}
                  height={400}
                  columns={4}
                  data={products}
                />
              )
            }
          </div>
        </Container>

        {/* <div className={styles.attributeContainer}>
          <Split
            image={'/cloth.png'}
            alt={'attribute description'}
            title={'Sustainability'}
            description={
              'We design our products to look good and to be used on a daily basis. And our aim is to inspire people to live with few timeless objects made to last. This is why quality over quantity is a cornerstone of our ethos and we have no interest in trends or seasonal collections.'
            }
            ctaText={'learn more'}
            cta={() => navigate('/blog')}
            bgColor={'var(--standard-light-grey)'}
          />
        </div> */}
      </div >
      {
        !!facebookPixelId && (
          <Script strategy="idle">{`
            fbq('track', 'ViewContent', {
              "content_name": "${product.name}",
              "content_ids": "['${product.wordpress_id}']",
              "content_type": "product",
              "contents":${JSON.stringify([{ id: `${product.wordpress_id}`, quantity: 1 }])},
              "value": ${product.price},
              "currency": "DZD",
              "source": "zzenz",
              "version": "1.0.0",
              "timestamp": "${Date.now()}"
            }, { "eventID": "${fbEventID}" });
          `}</Script>
        )
      }
    </Layout >
  );
};

export const pageQuery = graphql`
  query ($id: String!, $productId: Int!, $categories: [Int]) {
    categories: allWcProductsCategories(
      filter: {
        wordpress_id: {
          in: $categories
        }
      },
      sort: {
        fields: [wordpress_id],
        order: ASC
      },
      limit: 2
    ) {
      nodes {
        id
        name
        slug
      }
    }
    recommendedProducts: allWcProducts(
      filter: {
        category_ids: { in: $categories }
        wordpress_id: { ne: $productId }
      }
      limit: 4
    ) {
      nodes {
        name
        slug
        id
        regular_price
        price
        wordpress_id
        description
        short_description
        attributes {
          id
          name
          options
          visible
        }
        images {
          id
          src
          height
          width
        }
      }
    }
    product: wcProducts(id: { eq: $id }) {
      id
      wordpress_id
      name
      slug
      regular_price
      price
      description
      short_description
      sku
      length
      height
      width
      weight
      images {
        id
        src
        height
        width
      }
      thumbnail {
        id
        src
        height
        width
      }
      attributes {
        id
        name
        options
        visible
      }
      date_created {
        date
      }
      date_modified {
        date
      }
      stock_quantity
      stock_status
      manage_stock
    }
    storeDetails: zzStoreWebsiteDetails {
      product_stock_url
      store_shipping
      store_name
      store_primary_settings {
        store_analytics {
          facebook_pixel
        }
      }
    }
  }
`;

export default ProductPage;
