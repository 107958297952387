import React from "react";
import CurrencyFormatter from '../CurrencyFormatter';
import * as styles from "./index.module.css";

/**
 * 
 * @param {import("./types")} props
 * @returns 
 */
export default function PriceTag({ price, regular_price, className }) {
  const onSale = price < regular_price;

  return (
    <div className={className}>
      <CurrencyFormatter
        amount={price}
        currency={'DZD'}
      />{` `}
      {
        onSale && (
          <CurrencyFormatter
            amount={regular_price}
            currency={'DZD'}
            className={styles.priceLineThrough}
          />
        )
      }

    </div>
  )
}