import { Dispatch, useEffect, useState } from "react";
import useAbortController from "../use-abort-controller";
import { Action, GlobalReducerActionType } from "../../reducers/types";
import httpRequest from "../../http";
import { scrollIntoView } from "../../ui";
import { customEvent } from "../../analytics/facebook-pixel";

type UseProductAvailabilityParams = {
  dispatch: Dispatch<Action<GlobalReducerActionType, any>>;
  product_stock_url: string;
  product_id: string | number | undefined;
  facebookPixelId?: string | undefined;
}

/**
 * Check product availability by verifying if is not out of stock
 * 
 * @param config contains details about the product id, sotck url..
 * @returns [true/false] true if not available otherwise false
 */
export default function useProductAvailability({
  dispatch,
  product_stock_url,
  product_id,
  facebookPixelId
}: UseProductAvailabilityParams) {
  const [isNotAvailableInStock, setIsNotAvailableInStock] = useState(false);
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();

  useEffect(() => {
    const checkProductAvailability = async () => {
      cancelPreviousRequest();
      const response = await httpRequest({
        url: `${product_stock_url}/${product_id}/availability?quantity=1`,
        requestConfig: {
          method: "GET",
          signal: newAbortSignal()
        }
      });

      if (response?.code !== "success") {
        await dispatch({
          type: "ERROR",
          payload: {
            global: "نفد هذا المنتج من المخزن"
          } || response.errors
        });
        setIsNotAvailableInStock(true);
        const elementClassName = response?.errors?.global
          ? ".alert"
          : ".invalid-feedback";
        scrollIntoView(document.querySelector(elementClassName));
        !!facebookPixelId && customEvent("ServerErrorInAddToCart", {
          contents: response.errors
        });
        !!facebookPixelId && customEvent("ProductStockCheck", {
          status: "outofstock"
        });
        return;
      } else {
        setIsNotAvailableInStock(false);
      }

    }
    checkProductAvailability();

    return () => {
      cancelPreviousRequest();
    }
  }, []);

  return [isNotAvailableInStock];
}