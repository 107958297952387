// extracted by mini-css-extract-plugin
export var accordionTitle = "index-module--accordionTitle--iWeKA";
export var actionContainer = "index-module--actionContainer--A0JsF";
export var addToButtonContainer = "index-module--addToButtonContainer--SbibI";
export var attributeContainer = "index-module--attributeContainer---XP5N";
export var content = "index-module--content--AQRbX";
export var description = "index-module--description--a6-o5";
export var details = "index-module--details--ne1I5";
export var gallery = "index-module--gallery--7Vj+N";
export var heartFillContainer = "index-module--heartFillContainer--4nD5J";
export var hide = "index-module--hide--Z6DzP";
export var information = "index-module--information--ZhLrY";
export var informationContainer = "index-module--informationContainer--0LYt6";
export var mainActionsContainer = "index-module--mainActionsContainer--QXy5k";
export var priceContainer = "index-module--priceContainer--DHf7h";
export var quantityContainer = "index-module--quantityContainer--3mGJv";
export var root = "index-module--root--S4Ur9";
export var show = "index-module--show--uPm44";
export var sizeContainer = "index-module--sizeContainer--A4Bck";
export var suggestionContainer = "index-module--suggestionContainer--hSC6o";
export var vendor = "index-module--vendor--+TJNJ";
export var wishlistActionContainer = "index-module--wishlistActionContainer--MCZBo";